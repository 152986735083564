* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #E9E9E9;
}

a {
  text-decoration: none !important;
}

::placeholder {
  color: #AEAEAE !important
}

.white-color {
  color: white !important;
}

.bg-green {
  background: #2D880C;
}

.wrapper-background-login {
  background: url('./assets/img/background.png') no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
}
.wrapper-login-horizontal {
  background-color: rgba(255, 255, 255, 0.8);
  width: calc(100vw - 20vw);
  height: calc(100vh - 20vw);
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  overflow: auto;
}
.wrapper-login-vertical {
  background-color: rgba(255, 255, 255, 0.8);
  width: calc(100vw - 20vw);
  height: calc(100vh - 40vh);
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.image-logo-login {
  width: 160px;
  height: 180px;
  margin: 50px auto;
}


.container {
  padding: 20px 30px;
  background-color: #E9E9E9;
  max-width: 600px;
}

.wrapper-list-nco {
  margin-top: 20px;
  min-height: 500px;
  margin-bottom: 20px;
}

.text-list-nco {
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #2D880C;
  text-transform: uppercase;
  text-align: center;

}

.item-nco {
  border-bottom: none;
  background: rgba(255, 255, 255, 0.6);
  padding: 10px 0;
  padding-left: 14px;
  border-radius: 2px;
  padding-right: 4px;
  margin: 10px 0;
}
.item-packages:hover,
.item-nco:hover{
  opacity: .8;
}

.item-nco.item-green {
  background: #8CE36C;
}

.item-packages {
  border: 1px solid #387C2B !important;
  padding: 10px 0;
  padding-left: 14px;
  border-radius: 5px;
  padding-right: 4px;
  margin: 10px 0;
}

.text-packages {
  color: #2D880C
}

.point-month {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  display: flex;
  align-items: center;

  color: #3C7C2C;
  text-shadow: 0px 2px 4px rgb(150, 150, 150);
}

.wrapper-info {
  background: #fff;
  padding: 14px 17px;
  box-shadow: 0px 4px 8px #b5b5b5;
  margin-top: 20px;
  border-radius: 10px
}

.wrapper-head {
  color: rgb(255, 255, 255);
  line-height: 20px;
  margin: 0;
  text-align: center;
  box-shadow: none;
}

.wrapper-head.head-green {
  background: rgb(124, 192, 66);
}

.wrapper-head.head-red {
  background: #B7403D;
}

.wrapper-head.head-yellow {
  background: #FCCD26;
}

.text-member {
  font-size: 14px;
  font-weight: 700;
}

.bold-text-member {
  font-size: 50px;
  font-weight: bold;
  line-height: 50px;
}

.info-head {
  font-weight: 500;
  font-size: 16px;
}
.info-item{
  font-size: 15px;
}
.bold-text-info{
  font-size: 18px;
}
.bold-wrapper-member{
  padding-left: 20px;
}
.statistic-lastest{
  font-size: 16px;
}
.logout-text{
  text-align: center;
  cursor: pointer;
  position: relative;
  bottom: 10px;
  right: 0;
  left: 0;
  padding-block: 10px;
  border-top: 1px solid #b1b1b1;
  height: 70px;
}
.text-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
div .text-uppercase{
  word-wrap: break-word !important;
  word-break: keep-all !important;
}
.wrapper-forget-password {
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  padding: 50px 200px;
}
.wrapper-forget-password .ant-form-item-has-error :not(.ant-input-disabled).ant-input{
  background-color: transparent;
}
.ant-message-notice-content .anticon svg {
  margin-bottom: 6px;
}


@media only screen and (min-width: 1200px) {
  .wrapper-login-horizontal {
    height: calc(100vh - 40px);
  }
  .wrapper-login-vertical {
    width: calc(100vw - 60vw);
  }
  .wrapper-forget-password {
    padding: 50px 400px;
  }
}
@media only screen and (max-width: 414px) {
  .wrapper-login-vertical {
    width: calc(100vw - 40px);
    height: calc(100vh - 40px);
  }
  .wrapper-forget-password {
    padding: 50px 20px;
  }
}
@media only screen and (min-width: 320px) and (max-height: 568px) {
  
  .text-member {
    font-size: 11px;
  }
  .wrapper-list-nco {
    min-height: 280px;
    margin-bottom: -20px;
  }
  .bold-text-member {
    font-size: 40px;
    font-weight: bold;
    line-height: 40px;
  }

  .wrapper-info {
    padding: 10px 13px;
  }

  .wrapper-info.wrapper-head {
    font-size: 12px;
  }
  .statistic-lastest{
    font-size: 14px;
  }
  .info-item,
  .info-head {
    font-size: 13px;
  }
  .bold-text-info{
    font-size: 16px;
  }
  .bold-wrapper-member{
    padding-left: 20px;
  }
  .container {
    padding: 10px 16px;
  }
}